import React, { useEffect } from 'react'

//Multimedia

import driveThru from '../../../../Imgs/Home/whyDriveThru.jpg'
import saveTime from '../../../../Imgs/newIcons/bgEntregadores.jpg'

function DriveThru() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="flex justify-center px-[35px]">
        <div className="container ">
          <div className="h-[200px] flex flex-col justify-center md:h-[300px]">
            <h1 className="text-center text-xl text-gray-700 md:text-4xl mx-[10px]  font-bold tituloPrincipal">
              Pide en minutos y pasa en segundos con el nuevo{' '}
              <span className="text-Main">Drive Thru </span>
            </h1>
          </div>
          <div className="grid md:grid-cols-2 md:gap-10 md:mb-[80px]">
            <div className="flex flex-col">
              <img src={driveThru} width={600} height={400} />
              <div>
                <h1 className="tituloSecundario my-[30px] text-center md:text-start">
                  ¿Por qué Drive Thru?
                </h1>
                <p className="textoGeneral text-justify">
                  Una nueva experiencia centralizada y ajustable a las
                  necesidades de los usuarios. El servicio Drive Thru ahora
                  ofrecerá varias marcas de tus restaurantes favoritos. Esto
                  beneficiará a toda nuestra comunidad a la hora de ahorrar
                  tiempo, ya que podemos ordenar nuestros productos rápidamente
                  sin tener que salir del automóvil.
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-10 mb-20 md:mt-0">
              <img src={saveTime} width={600} height={400} />
              <div>
                <h1 className="tituloSecundario my-[30px] text-center md:text-start">
                  Comodidad y ahorro
                </h1>
                <p className="textoGeneral text-justify">
                  Muchas veces nos encontramos en situaciones en las que nos
                  resulta difícil ahorrar tiempo para ordenar comida, la
                  necesidad de bajarnos para hacer pedidos sin contar que muchas
                  veces se va más del tiempo estipulado. Con nuestro servicio
                  exclusivo de Drive Thru podrás ordenar y pasar sin largas
                  esperas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DriveThru
