import phones from 'src/Imgs/Home/phones.png'

import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import whatsapp from '../../../../Imgs/svg/brand-whatsapp.svg'

function SoonMobile() {
  return (
    <>
      <div className="flex justify-center h-full items-center bg-[#F8F8F8]">
        <div className="container">
          <div className=" flex items-center justify-center">
            <div className="relative">
              <div className="p-2">
                <div className="flex flex-col items-center text-center">
                  <h1 className="text-3xl md:text-5xl font-bold mt-8">
                    Nuestra plataforma{' '}
                    <span className="text-Main">Pide y Pasa</span>, reinventada
                    para los negocios
                  </h1>
                  <p className="text-justify md:text-start text-base md:text-lg mb-[50px] mt-[50px] ">
                    Una plataforma para que los negocios de todos los tamaños
                    administren sus productos y ofertas en tiempo real.
                  </p>
                  <motion.button
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 1 }}
                    className="bg-[#47C257] mx-auto mt-[20px] px-[20px] py-[10px] flex items-center text-white font-semibold rounded-full"
                  >
                    <img
                      src={whatsapp}
                      alt="whatsapp"
                      className="h-[35px] mr-[15px] filter invert"
                    />
                    <Link
                      to={'https://api.whatsapp.com/send?phone=573023050307'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hablar con un asesor
                    </Link>
                  </motion.button>
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center">
                <div className="flex justify-center bg-transparent mx-auto">
                  <div className="block lg:hidden">
                    <img src={phones} alt="phones" className="z-50  mx-auto" />
                  </div>
                </div>
              </div>
            </div>
            <img
              src={phones}
              alt="phones"
              className="z-50 hidden lg:block xl:h-[544px] xl:w-[833px] mt-48 xl:mt-14"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SoonMobile
