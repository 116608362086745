import { API_URL, HTTP_STATUSES } from './constants'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import axios from 'axios'

const state = API_URL || 'http://localhost'

const success = () => toast.success('Tus datos llegaron de manera correcta.')
const warn = () =>
  toast.warn('Ups! Recuerda que todos los campos deben ser completados.')
// const error = () =>
//   toast.error('Ups! No se envió  el formulario, inténtalo otra vez.')

export const api = {
  async getCategories() {
    try {
      const res = await fetch(`${state}/register/categories`)
      const response = await res.json()
      if (res.status === HTTP_STATUSES.OK) {
        return response
      } else {
        return response
      }
    } catch (error) {
      //console.log(error)
    }
  },

  async getPlans() {
    try {
      const res = await fetch(`${state}/register/plans`)
      const response = await res.json()
      if (res.status === HTTP_STATUSES.OK) {
        return response
      } else {
        return response
      }
    } catch (error) {
      // console.log(error)
    }
  },

  async postContact(requestData) {
    try {
      const response = await fetch(`${state}/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // 'Access-Control-Allow-Origin': '*',
          // "Access-Control-Allow-Credentials": false
        },
        body: JSON.stringify(requestData)
      })

      if (response.status < 400) {
        success()
      } else {
        warn()
      }
    } catch (error) {
      error()
    }
  },

  async postRegister(requestData) {
    try {
      return await axios.post(`${state}/register/signup`, requestData)
    } catch (error) {
      return error.response
    }
  },

  async postNewsletter(requestData) {
    try {
      return await axios.post(`${state}/register/clientNewsletter`, requestData)
    } catch (error) {
      return error.response
    }
  },

  async postWorkWithUs(requestData) {
    const { name, email, phone, message, location, base64Data } = requestData

    try {
      const response = await fetch(`${state}/work-with-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // "Access-Control-Allow-Credentials": false
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          message,
          location,
          resume: base64Data
        })
      })

      if (response.status < 400) {
        success()
      } else {
        warn()
      }
    } catch (error) {
      error()
      // console.log('desde el back', response)
    }
  },
  async getCountries() {
    try {
      const response = await fetch(`${state}/country`)
      const data = await response.json()
      return data
    } catch (error) {
      console.error(error)
      return null
    }
  }
}
