import appsco from '../Imgs/Sponsors/appsco.png'
import innpulsa from '../Imgs/Sponsors/innpulsa.png'
import pantera from '../Imgs/Sponsors/pantera.png'
import mintic from '../Imgs/Sponsors/mintic.png'
function Sponsors() {
  return (
    <div className="flex justify-center md:w-[100%] bg-gray">
      <div className="py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <div className="bg-white p-4">
              <img src={mintic} alt="Patrocinador 1" height={150} width={300} />
            </div>
            <div className="bg-white p-4">
              <img src={appsco} alt="Patrocinador 1" height={150} width={300} />
            </div>
            <div className="bg-white p-4">
              <img
                src={innpulsa}
                alt="Patrocinador 1"
                height={150}
                width={300}
              />
            </div>
            <div className="bg-white p-4">
              <img
                src={pantera}
                alt="Patrocinador 1"
                height={150}
                width={300}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sponsors
