import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
//import { IMAGE_BUCKET } from 'src/api/constants'

import iconFood from 'src/Imgs/svg/comida.svg'
import iconEmployee from 'src/Imgs/svg/drivethru.svg'
import bgEntregadores from '../../../../Imgs/Home/chico.png'
// import iconUser from 'src/Imgs/svg/icon-user.svg'
// import activeIconUser from 'src/Imgs/svg/activeIconUser.svg'
import activeIconEmployee from 'src/Imgs/Home/activeDrivethru.png'
import activeIconFood from 'src/Imgs/Home/activeComida.png'
import { Link } from 'react-router-dom'
import workerCut from '../../../../Imgs/Home/chica.png'
import pleasant from '../../../../Imgs/Home/pleasant.png'
import mensajero from '../../../../Imgs/Home/mensajero.png'

// const hero1 = `${IMAGE_BUCKET}/homebg1.webp`
const hero2 = workerCut
// const hero2 = `${IMAGE_BUCKET}/vendedorbg.webp`
const hero1 = bgEntregadores
//const hero3 = `${IMAGE_BUCKET}/clientebg.webp`

const restaurantTab = {
  header: 'Adelanta tu pedido sin filas con nuestra plataforma.',
  body: 'Sé uno de los restaurantes pioneros en incorporar esta solución innovadora. ',
  button: 'Estoy interesado',
  route: 'registro-restaurante'
}

const employeeTab = {
  header: 'El nuevo sistema Drive Thru, una innovación para tu negocio.',
  body: 'Una solución innovadora y reinventada para hacer crecer tu negocio.',
  button: 'Estoy interesado',
  route: 'entregadores'
}

// const userTab = {
//   header: 'Los clientes retiran con comodidad',
//   body: 'Cuando estés listo para recoger tu orden, selecciona tu método de recogido preferido.',
//   button: 'Estoy interesado',
//   footer: 'Conoce los términos y condiciones para registrar tu restaurante',
//   route: 'clientes'
// }

function Hero() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [bgImage, setBgImage] = useState(hero1)
  const [activeIcon, setActiveIcon] = useState('restaurant')
  const [contentTab, setContentTab] = useState(restaurantTab)

  const handleChangeTab = (_, param) => {
    if (param === 'restaurant-tab') {
      setBgImage(hero1)
      setActiveIcon('restaurant')
      setContentTab(restaurantTab)
    }
    if (param === 'employee-tab') {
      setBgImage(hero2)
      setActiveIcon('employee')
      setContentTab(employeeTab)
    }
    // if (param === 'user-tab') {
    //   setBgImage(hero3)
    //   setActiveIcon('user')
    //   setContentTab(userTab)
    // }
  }

  return (
    <>
      <>
        <div
          className="relative md:h-[800px]  flex items-start w-full h-full"
          style={{ zIndex: 0 }}
        >
          <div
            className="hidden md:block absolute inset-0 bg-no-repeat "
            style={{
              backgroundImage: ` url(${bgImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />

          <div className=" z-50 flex justify-center w-full md:m-[100px] ">
            <div className="container flex lg:pl-[40px]">
              <div className="bg-white h-[500px]">
                <div className="mb-4 ">
                  <ul
                    className=" rounded-xl flex flex-nowrap text-sm font-medium text-center justify-evenly bg-gray-100"
                    id="myTab"
                    data-tabs-toggle="#myTabContent"
                  >
                    <li
                      className="mr-2 ml-2"
                      onClick={(event) =>
                        handleChangeTab(event, 'restaurant-tab')
                      }
                    >
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 1 }}
                        className={`inline-block border-b-4 w-[130px] lg:w-[200px] ${
                          activeIcon === 'restaurant' && 'border-[#FF484A]'
                        } `}
                        id="restaurant-tab"
                        data-tabs-target="#restaurant"
                        type="button"
                      >
                        <img
                          src={
                            activeIcon === 'restaurant'
                              ? activeIconFood
                              : iconFood
                          }
                          className="mt-[26px] mx-auto h-[25px] w-[25px] mb-1"
                          alt=""
                        />
                        <small
                          className={`text-xs  ${
                            activeIcon === 'restaurant'
                              ? 'font-bold text-Main'
                              : 'font-medium letraMain'
                          }`}
                        >
                          Pick up
                        </small>
                      </motion.button>
                    </li>
                    <li
                      className="mr-2 ml-2"
                      onClick={(event) =>
                        handleChangeTab(event, 'employee-tab')
                      }
                    >
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 1 }}
                        className={`inline-block border-b-4 w-[130px] lg:w-[200px] ${
                          activeIcon === 'employee' && 'border-[#FF484A]'
                        } `}
                        id="employee-tab"
                        data-tabs-target="#employee"
                        type="button"
                      >
                        <img
                          src={
                            activeIcon === 'employee'
                              ? activeIconEmployee
                              : iconEmployee
                          }
                          className="mt-[26px] mx-auto h-[25px] w-[25px] mb-1"
                          alt=""
                        />
                        <small
                          className={`text-xs ${
                            activeIcon === 'employee'
                              ? 'font-bold text-Main'
                              : 'font-medium letraMain'
                          }`}
                        >
                          Drive Thru
                        </small>
                      </motion.button>
                    </li>
                    {/* <li
                        className="mr-2 ml-2"
                        onClick={(event) => handleChangeTab(event, 'user-tab')}
                      >
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 1 }}
                          className={`inline-block border-b-4 w-[100px] ${
                            activeIcon === 'user' && 'border-[#FF484A]'
                          } `}
                          id="user-tab"
                          data-tabs-target="#user"
                          type="button"
                        >
                          <img
                            src={
                              activeIcon === 'user' ? activeIconUser : iconUser
                            }
                            className="mt-[26px] mx-auto h-[25px] w-[25px] mb-1"
                            alt=""
                          />
                          <small
                            className={`text-xs ${
                              activeIcon === 'user'
                                ? 'font-bold text-Main'
                                : 'font-medium letraMain'
                            }`}
                          >
                            Clientes
                          </small>
                        </motion.button>
                      </li> */}
                  </ul>
                </div>

                <div id="myTabContent" className="relative h-[340px]">
                  <div
                    className="flex justify-center md:max-w-[500px]  h-[400px] flex-col pl-[20px] pr-[20px] text-left"
                    id="restaurant"
                    role="tabpanel"
                    aria-labelledby="restaurant-tab"
                  >
                    <h1 className="text-2xl font-bold  mb-[75px] md:mb-[20px] h-[80px] md:text-3xl md:h-[100px]">
                      {contentTab.header}
                      {activeIcon === 'employee' && (
                        <small className="ml-2 pt-2 bg-[#3682F4] text-white text-center text-sm font-semibold px-2.5 py-1 rounded">
                          Próximamente
                        </small>
                      )}
                    </h1>

                    <p className="text-base md:text-lg h-[70px] mb-[40px]">
                      {contentTab.body}
                    </p>
                    <div className="w-[300px]">
                      <Link to="/registro-restaurante">
                        <motion.div
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 1 }}
                          className="w-[300px] h-[40px]  bg-Main  text-white rounded-3xl mb-[40px]"
                        >
                          <p className="text-white itemButton font-semibold">
                            {contentTab.button}
                          </p>
                        </motion.div>
                      </Link>
                    </div>
                    <p className="text-xs font-bold">
                      Conoce nuestra
                      <Link to="/politica-privacidad">
                        <b className="text-[#3682F4]">
                          {' '}
                          política de privacidad{' '}
                        </b>
                      </Link>
                      para registrar tu restaurante
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <div
        className={`md:hidden min-h-[300px]  bg-center bg-contain bg-no-repeat ${
          activeIcon !== 'restaurant' ? 'bg-[#FDAD46]' : 'bg-[#CCC193]'
        }`}
        style={{
          backgroundImage:
            activeIcon !== 'restaurant'
              ? `url(${pleasant})`
              : `url(${mensajero})`
        }}
      />
    </>
  )
}

export default Hero
