import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home/Home'
// import BuyPlan from '../Pages/Plans/BuyPlan'
// import AboutUs from '../Pages/AboutUs'
// import WeOffer from '../Pages/WeOffer'
import HowWorks from 'src/Pages/HowWorks'
//import WorkUs from 'src/Pages/WorkUs'
import SignupRestaurant from 'src/Pages/SignupRestaurant'
// import Contact from 'src/Pages/Contact'
import PrivacyPolicy from 'src/Pages/PrivacyPolicy/PrivacyPolicy'
// import ConditionsOfService from 'src/Pages/ConditionsOfService/ConditionsOfService'
//import TermsAndConditions from 'src/Pages/TermsAndConditions/TermsAndConditions'
// import Downloads from 'src/Pages/Downloads/Downloads'
// import Deliverers from 'src/Pages/Deliverers'
// import Customer from 'src/Pages/Customer'
// import Ourphilosophy from 'src/Pages/OurPhilosophy'
//import Home from "../Pages/Home/Home";

/* ------------------------------- Import plantilla kendo default ------------------------------- */

const AppRoutes = () => {
  return (
    <Routes>
      {/* Rutas accesibles por todos */}
      <Route exact path="/" forceRefresh={true} element={<Home />} />
      {/* <Route exact path="/planes" element={<BuyPlan />} />
      <Route exact path="/nosotros" element={<AboutUs />} />
      <Route exact path="/lo-que-ofrecemos" element={<WeOffer />} />
      <Route exact path="/quienes-somos" element={<AboutUs />} /> */}
      <Route exact path="/como-funciona" element={<HowWorks />} />
      {/* <Route exact path="/trabaja-con-nosotros" element={<WorkUs />} />
      <Route exact path="/contactanos" element={<Contact />} />
      
      <Route exact path="/entregadores" element={<Deliverers />} />
      <Route exact path="/clientes" element={<Customer />} />
      <Route exact path="/Ourphilosophy" element={<Ourphilosophy />} /> */}
      {/* <Route
        exact
        path="/condiciones-servicio"
        element={<ConditionsOfService />}
      /> */}
      <Route exact path="/politica-privacidad" element={<PrivacyPolicy />} />
      {/* <Route
        exact
        path="/terminos-condiciones"
        element={<TermsAndConditions />}
      /> */}
      {/* <Route exact path="/descargas" element={<Downloads />} /> */}
      <Route
        exact
        path="/registro-restaurante"
        element={<SignupRestaurant />}
      />
    </Routes>
  )
}

export default AppRoutes
