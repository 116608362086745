import shopLogo from 'src/Imgs/newIcons/shopLogo.png'
import adminLogo from 'src/Imgs/newIcons/adminLogo.png'
import saleLogo from 'src/Imgs/newIcons/saleLogo.png'

const cart = shopLogo
const promo = adminLogo
const cash = saleLogo
const liStyles =
  'rounded-2xl mb-[30px] shadow-xl w-[400px] h-[400px] md:h-[600px] flex flex-col items-center justify-end  p-[30px]'

function Attributes() {
  return (
    <div className="flex justify-center  md:w-[100%]">
      <div className="container">
        <div className="conatinerSection">
          <div className="w-[80%] h-[150px] flex flex-col justify-center md:h-[300px]">
            <h1 className="text-center text-xl text-gray-700 md:text-4xl  font-bold">
              Controla tus ofertas y administra tus{' '}
              <p className="text-Main">ventas</p>
            </h1>
          </div>
        </div>

        <div className="conatinerSection">
          <nav className="w-[90%]">
            <ul>
              <li className={liStyles}>
                <img
                  className="rounded-2xl w-[200px] md:w-[400px]"
                  src={cart}
                  alt=""
                />
                <p className="text-xl md:text-2xl font-bold mt-[40px] mb-[40px] md:mt-[40px] md:mb-[70px] ">
                  Registra tu restaurante y sucursales
                </p>
              </li>
              <li className={liStyles}>
                <img
                  className="rounded-2xl w-[200px] md:w-[400px]"
                  src={promo}
                  alt=""
                />
                <p className="text-xl md:text-2xl font-bold mt-[40px] mb-[40px] md:mt-[40px] md:mb-[70px] ">
                  Administra tus productos de manera eficiente
                </p>
              </li>
              <li className={liStyles}>
                <img
                  className="rounded-2xl w-[200px] md:w-[400px]"
                  src={cash}
                  alt=""
                />
                <p className="text-xl md:text-2xl font-bold mt-[40px] mb-[40px] md:mt-[40px] md:mb-[70px] ">
                  Incremente tus ventas de manera segura
                </p>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Attributes
