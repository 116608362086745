import React from 'react'

import './App.css'

// Imports
import Layout from './Pages/Layouts/MainLayout'

function App() {
  return <Layout />
}

export default App
