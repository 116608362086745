/* eslint-disable no-undef */
const API_URL = process.env.REACT_APP_PYP_API_URL

const IMAGE_BUCKET = process.env.REACT_APP_IMAGE_BUCKET

const HTTP_STATUSES = {
  OK: 200,
  VALID: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  SERVER_ERROR: 500
}

export { API_URL, HTTP_STATUSES, IMAGE_BUCKET }
