import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import AppRoutes from '../../Routes/AppRoutes'
import '../../index.css'
//import Sponsors from 'src/Components/Sponsors'

function MainLayout() {
  return (
    <div className="overflow-hidden">
      <Navbar />
      <AppRoutes />
      {/* <Sponsors /> */}
      <Footer />
    </div>
  )
}

export default MainLayout
