import React, { useEffect } from 'react'
import { motion } from 'framer-motion'

//Multimedia
// import House from '../../Imgs/svg/House.svg'
import houseAlone from '../../Imgs/newIcons/houseAlone.png'
import laptop from '../../Imgs/HowWork/laptop.png'
import documentIcon from '../../Imgs/HowWork/documentIcon.png'
import delivery from '../../Imgs/HowWork/delivery.png'
import deliveryMan from '../../Imgs/HowWork/deliverMan.png'
import girlClock from '../../Imgs/HowWork/girlClock.png'
import burger from '../../Imgs/HowWork/eatImg/burger.png'
import coffe from '../../Imgs/HowWork/eatImg/coffe.png'
import snack from '../../Imgs/HowWork/eatImg/snack.png'
import bread from '../../Imgs/HowWork/eatImg/bread.png'
import donas from '../../Imgs/HowWork/eatImg/donas.png'
import iceCream from '../../Imgs/HowWork/eatImg/iceCream.png'
import salas from '../../Imgs/HowWork/eatImg/salas.png'
import utils from '../../Imgs/HowWork/eatImg/utils.png'

function HowWorks() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const classIcons = 'hidden md:block  h-[90px] w-[90px] mr-[25px] md:mr-0'

  const classIconsMobile = 'block md:hidden h-[70px] w-[70px] p-[8px]'

  const classCategories =
    ' flex flex-col items-center p-[5px] h-[110px] w-[80px] m-[15px]  '

  return (
    <>
      <div className=" flex justify-center">
        <motion.div
          initial={{ x: -400 }}
          transition={{ duration: 1.2 }}
          animate={{ x: 0 }}
          className="container"
        >
          <div className="m-[40px] md:m-[80px] grid grid-cols-1 md:grid-cols-2  justify-center   items-center">
            <div className="flex flex-col mb-[100px]">
              <h1 className=" tituloPrincipal text-center md:text-left mb-[30px] md:mb-[60px]">
                ¿Cómo funciona Pide y Pasa?
              </h1>
              <p className=" textoGeneral text-justify">
                Nuestro servicio principal es desarrollar tecnología que conecte
                a los restaurantes con el pedido de los usuarios. Conoce cómo
                funciona nuestra plataforma paso a paso:
              </p>
            </div>
          </div>
          <div className=" items-center justify-center md:justify-evenly flex">
            <img src={houseAlone} alt="" srcSet="" className={classIcons} />
            <div className="items-start relative border-l-2 pl-[30px] flex gap-7 flex-col w-[75%] md:w-[40%] ">
              <figure>
                {' '}
                <img
                  src={houseAlone}
                  alt=""
                  srcSet=""
                  className="block md:hidden h-[80px] w-[80px] p-[8px]"
                />
              </figure>
              <p className=" text-base  text-center md:text-left text-[#979797]">
                Paso 1
              </p>
              <h1 className="tituloSecundario tituloSecundario text-left ">
                Registra tu restaurante
              </h1>
              <p className=" textoGeneral text-justify mb-[60px] md:mb-[100px]">
                Elige una categoría para tu restaurante y entrega fácil a tus
                clientes los productos el mismo día.
              </p>
              <div className="left-[-6px] top-0 absolute h-[10px] w-[10px] bg-slate-800"></div>
            </div>
          </div>
          <div className=" items-center justify-center md:justify-evenly flex">
            <img src={laptop} alt="" srcSet="" className={classIcons} />
            <div className="items-start relative border-l-2 pl-[30px] flex gap-7 flex-col w-[75%] md:w-[40%] ">
              <figure>
                <img
                  src={laptop}
                  alt=""
                  srcSet=""
                  className="block md:hidden h-[80px] w-[80px] p-[8px]"
                />
              </figure>
              <p className="  text-base  text-center md:text-left text-[#979797]">
                Paso 2
              </p>
              <h1 className="tituloSecundario text-left ">
                Recibe el pedido del cliente
              </h1>
              <p className=" textoGeneral text-justify mb-[70px] md:mb-[100px]">
                Coloca "online" tu negocio para recibir los pedidos de los
                clientes en el control de pedidos y de esta manera organizar las
                órdenes.
              </p>
              <div className="left-[-6px] top-0 absolute h-[10px] w-[10px] bg-slate-800"></div>
            </div>
          </div>
          <div className=" items-center justify-center md:justify-evenly flex">
            <img
              src={documentIcon}
              alt=""
              srcSet=""
              className="hidden md:block  h-[80px] w-[90px] mr-[25px] md:mr-0"
            />
            <div className="items-start relative border-l-2 pl-[30px] flex gap-7 flex-col w-[75%] md:w-[40%] ">
              <figure>
                <img
                  src={documentIcon}
                  alt=""
                  srcSet=""
                  className="block md:hidden h-[70px] w-[80px] p-[8px]"
                />
              </figure>
              <p className="  text-base  text-center md:text-left text-[#979797]">
                Paso 3
              </p>
              <h1 className="tituloSecundario text-left ">
                Espera la confirmación de recogida
              </h1>
              <p className=" textoGeneral text-justify mb-[70px] md:mb-[100px]">
                Espera la notificación del cliente "mandar a preparar" para que
                alistes todos los productos e ingredientes de su pedido.
              </p>
              <div className="left-[-6px] top-0 absolute h-[10px] w-[10px] bg-slate-800"></div>
            </div>
          </div>
          <div className=" items-center justify-center md:justify-evenly flex">
            <img src={delivery} alt="" srcSet="" className={classIcons} />
            <div className="items-start relative border-l-2 pl-[30px] flex gap-7 flex-col w-[75%] md:w-[40%] ">
              <figure>
                <img
                  src={delivery}
                  alt=""
                  srcSet=""
                  className={classIconsMobile}
                />
              </figure>
              <p className="  text-base  text-center md:text-left text-[#979797]">
                Paso 4
              </p>
              <h1 className="tituloSecundario text-left ">
                En camino al restaurante
              </h1>
              <p className=" textoGeneral text-justify mb-[70px] md:mb-[100px]">
                Una vez el cliente notifica, el sistema indicará cuando va
                retirar, selecciona el tiempo estimado de preparación y entrega.
              </p>
              <div className="left-[-6px] top-0 absolute h-[10px] w-[10px] bg-slate-800"></div>
            </div>
          </div>
          <div className=" items-center justify-center md:justify-evenly flex">
            <img src={deliveryMan} alt="" srcSet="" className={classIcons} />
            <div className="items-start relative  pl-[30px] flex gap-7 flex-col w-[75%] md:w-[40%] ">
              <figure>
                <img
                  src={deliveryMan}
                  alt=""
                  srcSet=""
                  className={classIconsMobile}
                />
              </figure>

              <p className="  text-base  text-center md:text-left text-[#979797]">
                Paso 5
              </p>
              <h1 className="tituloSecundario text-left ">Retiro del pedido</h1>
              <p className=" textoGeneral text-justify mb-[70px] md:mb-[100px]">
                Pídele al cliente el número PIN de entrega para que reciba su
                orden según el método de entrega seleccionado.
              </p>
              <div className="left-[-6px] top-0 absolute h-[10px] w-[10px] bg-slate-800"></div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex justify-center bg-slate-50">
        <div className="flex flex-wrap  container bg-slate-50 rounded-2xl py-[40px]">
          <div className=" flex hidden md:block  justify-center w-1/2">
            <div className="flex justify-center items-center h-full">
              <figure>
                <img
                  className=" max-h-[200px] mb-[30px] md:mb-[0px]  mt-[30px] md:mt-[0px] md:max-h-[400px]"
                  src={girlClock}
                  alt="delivery"
                />
              </figure>
            </div>
          </div>
          <div className="md:w-1/2 flex flex-col  lg:pr-[150px] mb-[30px] ">
            <h1 className="tituloPrincipal text-xl text-center md:text-left md:text-3xl font-semibold mb-[30px] md:mb-[60px]">
              ¿Qué estás esperando?
            </h1>
            <p className="textoGeneral text-justify pr-[30px] pl-[30px]  md:pl-[0px] mb-[35px]">
              Elige la categoría para tu negocio y hazlo crecer. Queremos
              brindarte soluciones integrales para ser tu mejor aliado en las
              actividades de tu negocio.
            </p>

            <div className="flex flex-wrap justify-center md:justify-start ">
              <div className={classCategories}>
                <figure>
                  <img src={burger} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center ">
                  Comida rápida
                </p>
              </div>
              <div className={classCategories}>
                <figure>
                  <img src={coffe} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center">Cafetería</p>
              </div>
              <div className={classCategories}>
                <figure>
                  <img src={snack} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center">Picadas</p>
              </div>
              <div className={classCategories}>
                <figure>
                  <img src={iceCream} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center">Heladería</p>
              </div>
              <div className={classCategories}>
                <figure>
                  <img src={donas} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center">Repostería</p>
              </div>
              <div className={classCategories}>
                <figure>
                  <img src={bread} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center">Panadería</p>
              </div>
              <div className={classCategories}>
                <figure>
                  <img src={salas} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center">
                  Healthy Food
                </p>
              </div>
              <div className={classCategories}>
                <figure>
                  <img src={utils} />
                </figure>
                <p className="textoGeneral mt-[13px] text-center">
                  Restaurantes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowWorks
