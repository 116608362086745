import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdCDGsoAAAAANBYlZ8B3FNxre0-ZR2ZNDvVtfDF"
      language="es"
      useRecaptchaNet="true"
      useEnterprise="false"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false // optional, default to false
        //appendTo: 'body', // optional, default to "head", can be "head" or "body",
        // nonce: undefined // optional, default undefined
      }}
      container={{
        parameters: {
          badge: 'inline',
          theme: 'dark'
        }
      }}
    >
      <App />
    </GoogleReCaptchaProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
