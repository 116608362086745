import React, { useEffect } from 'react'
import { Benefits, Comfort, Hero, SoonMobile, DriveThru } from './components'

import About from './components/About/About'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Hero />
      <About />
      <Comfort />
      <DriveThru />
      <Benefits />
      <SoonMobile />
    </>
  )
}

export default Home
