import { useEffect, useMemo, useState } from 'react'

export const useForm = (initialForm = {}, formValidations = {}) => {
  const [formState, setFormState] = useState(initialForm)
  const [formValidation, setFormValidation] = useState({})

  useEffect(() => {
    createValidators()
  }, [formState])

  useEffect(() => {
    setFormState(initialForm)
  }, [initialForm])

  const isFormValid = useMemo(() => {
    for (const formValue of Object.keys(formValidation)) {
      if (formValidation[formValue] !== null) return false
    }

    return true
  }, [formValidation])

  const onInputChange = ({ target }) => {
    let phoneCode = formState.phoneCode
    let flag = formState.flag
    let countryCode = formState.countryCode
    const { name, value } = target

    if ('countryId' === name) {
      const country = formState.countries.find(
        (country) => country.id === value
      )
      phoneCode = country.phoneCode
      flag = country.flag
      countryCode = country.countryCode
    }
    setFormState({
      ...formState,
      [name]: value,
      phoneCode,
      flag,
      countryCode
    })
  }

  const onResetForm = () => {
    setFormState(initialForm)
  }

  const createValidators = () => {
    const formCheckedValues = {}

    for (const formField of Object.keys(formValidations)) {
      const [fn, errorMessage] = formValidations[formField]

      formCheckedValues[`${formField}Valid`] = fn(formState[formField])
        ? null
        : errorMessage
    }

    setFormValidation(formCheckedValues)
  }

  return {
    ...formState,
    formState,
    setFormState,
    onInputChange,
    onResetForm,

    ...formValidation,
    isFormValid
  }
}
