import ticketIcon from 'src/Imgs/newIcons/money.png'
import grafickIcon from 'src/Imgs/newIcons/graphic.png'
import homeClock from 'src/Imgs/newIcons/homeClock.png'
import mouseIcon from 'src/Imgs/newIcons/branch.png'
import phones from '../../../../Imgs/newIcons/nena.png'
import appstore from '../../../../Imgs/appstore.png'
import playstore from '../../../../Imgs/playstore.png'

function Benefits() {
  return (
    <>
      <div className="flex justify-center h-full items-center bg-[#F8F8F8]">
        <div className="container">
          <div className=" flex items-center justify-center">
            <div className="relative">
              <div className="p-2">
                <div className="flex flex-col items-start text-left md:text-start">
                  <h1 className="text-3xl md:text-5xl font-bold mt-8">
                    Hacemos tu experiencia de compra aun más rápida.
                  </h1>
                  <p className="text-left md:text-start text-base md:text-lg mb-[50px] mt-[50px] ">
                    Próximamente disponible en las plataformas App Store y
                    Google Play.
                  </p>
                </div>
                <div className="flex flex-col md:flex-row items-center">
                  <div className="flex">
                    <img
                      className="mx-auto"
                      src={appstore}
                      alt="appstore"
                      height={65}
                      width={140}
                    />
                  </div>
                  <div className="flex">
                    <img
                      className="mx-auto"
                      src={playstore}
                      alt="playstore"
                      height={95}
                      width={150}
                    />
                  </div>
                </div>

                <div className="flex justify-center bg-transparent">
                  <div className="block lg:hidden">
                    <img
                      src={phones}
                      alt="phones"
                      className="z-50 max-w-[300px] h-[221px]  mt-4"
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              src={phones}
              alt="phones"
              className="z-50 hidden h-[400px] w-[600px] lg:block xl:h-[544px] xl:w-[833px] mt-48 xl:mt-14"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center  md:w-[100%] my-[30px] md:my-[100px]">
        <div className="container">
          <div className="flex flex-col items-center px-[30px]">
            <div className="my-[60px] flex justify-center">
              <div className="w-[70%]">
                <h1 className="text-center text-2xl md:text-5xl font-bold text-Main">
                  Beneficios
                </h1>
                <h1 className="text-center text-md lg:text-xl mt-6">
                  Hacemos que sea más fácil que nunca para los restaurantes
                  puedan crecer y tener ingresos extras.
                </h1>
              </div>
            </div>

            <nav className="">
              <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <li className="text-center my-[30px] flex flex-col items-center md:ml-[30px] md:mr-[30px]">
                  <div className="containerLogo">
                    <img src={homeClock} width={50} height={50} alt="" />
                  </div>
                  <h1 className="cardTitle md:h-[50px]">Practicidad</h1>
                  <p className="textoGeneral text-justify">
                    Las compras mediante este sistema suelen ser más
                    convenientes para los consumidores, ya que los procesos
                    entre la toma y entrega de pedido son mucho más ágiles.
                  </p>
                </li>
                <li className="text-center my-[30px] flex flex-col items-center md:ml-[30px] md:mr-[30px]">
                  <div className="containerLogo">
                    <img src={mouseIcon} width={50} height={50} alt="" />
                  </div>
                  <h1 className="cardTitle md:h-[50px]">Reducción de costos</h1>
                  <p className="textoGeneral text-justify">
                    Reduce los gastos de operación por el hecho de mitigar los
                    costos de limpieza e higiene del establecimiento y la
                    cantidad de personal requerido para dicha tarea.
                  </p>
                </li>
                <li className="text-center my-[30px] flex flex-col items-center md:ml-[30px] md:mr-[30px]">
                  <div className="containerLogo">
                    <img src={ticketIcon} width={50} height={50} alt="" />
                  </div>
                  <h1 className="cardTitle md:h-[50px]">
                    Aumento de rentabilidad
                  </h1>
                  <p className="textoGeneral text-justify">
                    Este sistema eleva el flujo de pedidos pues deja que los
                    clientes hagan rápidamente su compra en tiempo real, lo que
                    conlleva a un aumento en los ingresos.
                  </p>
                </li>
                <li className="text-center my-[30px] flex flex-col items-center md:ml-[30px] md:mr-[30px]">
                  <div className="containerLogo">
                    <img src={grafickIcon} width={50} height={50} alt="" />
                  </div>
                  <h1 className="cardTitle md:h-[50px]">
                    Crecimiento de los negocios
                  </h1>
                  <p className="textoGeneral text-justify">
                    Permite que más personas conozcan el restaurante. Adoptar
                    este sistema es una alternativa rentable y efectiva para
                    aumentar ventas y posicionar los negocios.
                  </p>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default Benefits
