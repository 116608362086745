import Fb from '../Imgs/newIcons/facebook.png'
import Inst from '../Imgs/newIcons/instagram.png'
import Twt from '../Imgs/newIcons/twitter.png'
import Yt from '../Imgs/newIcons/youtube.png'

import pypLogoC from '../Imgs/newIcons/pypLogoC.png'

function Footer() {
  return (
    <div className="flex justify-center bg-Main">
      <div className="container flex flex-col items-center  my-[30px] ">
        <div className=" flex flex-col items-center w-[400px]   px-[20px]  text-center lg:text-start my-3  ">
          <img src={pypLogoC} className="mr-[30px] w-[200px] " />
          <div className="flex justify-center w-full my-[30px]">
            <a
              href="https://www.facebook.com/profile.php?id=100088170187471"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Fb} className="w-[25px] h-[25px] mr-[25px]" />
            </a>
            <a
              href="https://www.instagram.com/pideypasa/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Inst} className="w-[25px] h-[25px] mx-[25px]" />
            </a>
            <a
              href="https://twitter.com/pideypasa"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twt} className="w-[25px] h-[25px] mx-[25px]" />
            </a>
            <a
              href="https://www.youtube.com/@PideYpasa/f"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Yt} className="w-[25px] h-[25px] mx-[25px]" />
            </a>
          </div>
          <p className="w-[200px] md:w-full text-white text-center text-sm">
            © 2023 Tecnicall Colombia S.A.S. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
