import React, { useEffect } from 'react'
import { motion } from 'framer-motion'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="flex justify-center">
      <div className="container">
        <motion.div
          initial={{ x: -400 }}
          transition={{ duration: 1.2 }}
          animate={{ x: 0 }}
          className="flex-col text-justify w-[85%] md:w-[70%] m-auto items-center p-[30px]"
        >
          <div className="h-[130px] flex items-center justify-center md:justify-start">
            <h1 className="tituloPrincipal  text-center">
              Política de privacidad
            </h1>
          </div>
          <div>
            <div className="mb-[30px]">
              <h3 className="tituloSecundario text-center md:text-left  mb-[20px] ">
                POLÍTICA DE TRATAMIENTO DE DATOS
              </h3>
              <p className="textoGeneral">
                La presente Política de Tratamiento de Datos Personales e
                Información (en adelante la "Política"), tiene como objetivo
                implementar las disposiciones contenidas en la Ley 1581 de 2012
                y en el Decreto 1475 de 2015, y demás normas relacionadas, en lo
                que se refiere exclusivamente a las bases de datos, archivos e
                información que contenga datos personales susceptibles de
                tratamiento, y explica como TECNICALL COLOMBIA S.A.S. recolecta,
                almacena, usa, circula y trata información que usted nos provee
                a través de distintos medios.
              </p>
            </div>

            <div className="mb-[30px]">
              <h3 className="tituloSecundario text-center md:text-left mb-[20px] ">
                {' '}
                DATOS DE CONTACTO DEL RESPONSABLE O ENCARGADO DEL DATO - ÁREA
                RESPONSABLE DE LA ATENCIÓN DE CONSULTAS, PETICIONES Y RECLAMOS.{' '}
              </h3>
              <p className="textoGeneral">
                TECNICALL COLOMBIA S.A.S. es una sociedad por acciones
                simplificada, legalmente constituida y existente bajo las leyes
                de la República de Colombia, constituida mediante escritura
                pública, inscrita en la Cámara de Comercio de Barranquilla, el
                10 de marzo de 2011, bajo el número NIT 900-420.620-3. Para
                efectos de esta Política, podrán contactarnos al siguiente
                correo electrónico contacto@tecnicall.com.co (en adelante, el
                "Responsable del Tratamiento")
              </p>
            </div>

            <div className="mb-[30px]">
              <h3 className="tituloSecundario text-center md:text-left mb-[20px] ">
                TRATAMIENTO DE LOS DATOS Y FINALIDAD
              </h3>
              <p className="textoGeneral">
                El Responsable del Tratamiento, previa autorización de los
                titulares de la información (en caso que esta sea requerida),
                puede obtener información de personas naturales, clientes o
                usuarios, de diferentes fuentes (comercios vinculados, entidades
                financieras, entidades participantes del sistema de pago,
                proveedores de servicios de telecomunicaciones, y fuentes
                propias entre otros). También es posible que tengamos una
                relación directa con personas naturales (empleados, proveedores,
                etc.), que podrán optar por brindarnos información personal en
                conexión con esa relación. Los tipos de datos personales que
                recolectamos son datos públicos, semi privados, y no serán
                recolectados datos sensibles o de menores de edad que no sean
                públicos.
                <br />
                <br />
                Los usuarios, clientes, trabajadores y proveedores autorizan
                expresamente al Responsable del Tratamiento a confirmar la
                información personal suministrada acudiendo a entidades
                públicas, compañías especializadas o centrales de riesgo, a sus
                contactos, o al empleador, así como a sus referencias
                personales, bancarias o laborales, entre otros. Esta información
                será tratada por el Responsable del Tratamiento en forma
                confidencial.
                <br />
                <br />
                Los datos personales proporcionados al Responsable del
                Tratamiento serán recolectados, almacenados, usados, analizados,
                circulados, actualizados y reportados para las siguientes
                finalidades, según se establezca en la respectiva autorización:
                (i) como elemento de análisis para establecer y mantener una
                relación contractual o comercial, cualquiera que sea su
                naturaleza, (ii) como elemento de análisis para hacer estudios
                de mercado o investigaciones comerciales o estadísticas, (iii)
                como herramienta para el ofrecimiento de productos o servicios
                propios o de terceros, (iv) como herramienta para el inicio de
                cualquier cobro prejudicial o judicial, (v) para que la
                información sea compartida, circulada y usada por otras
                entidades para cualquiera de los fines aquí previstos, (vi) para
                el adelantamiento de cualquier trámite ante una autoridad
                pública o una persona o entidad privada, respecto del cual la
                información resulte pertinente, (vii) para que toda la
                información referida a mi comportamiento crediticio, financiero,
                comercial, de servicios y datos de la misma naturaleza
                proveniente de terceros países sea consultada, suministrada,
                reportada, procesada o divulgada, (viii) para efectos de
                seguridad y de monitorear las actividades que se desarrollen
                dentro de los establecimientos de comercio, (ix) para que la
                información suministrada sea consultada en listas para la
                prevención del lavado de activos y financiación del terrorismo,
                (x) para el cumplimiento de cualquier otra obligación legal a
                cargo del Responsable del Tratamiento, (xi) para que la
                información sea transferida o transmitida a terceros ubicados
                dentro o fuera de la República de Colombia, para cualquiera de
                los fines aquí previstos.
              </p>
            </div>
            <div className="mb-[30px]">
              <h3 className="tituloSecundario text-center md:text-left mb-[20px] ">
                DERECHOS DE LOS TITULARES
              </h3>
              <p className="textoGeneral">
                El titular de los datos personales tiene los siguientes derechos
                frente al Responsable del Tratamiento:
              </p>
              <br />
              <p className="textoGeneral">
                1. Conocer, actualizar y rectificar sus datos personales; 2.
                Solicitar prueba de la autorización otorgada; 3. Ser informado
                por el Responsable del Tratamiento previa solicitud, respecto
                del uso que le ha dado a sus datos personales; 4. Presentar ante
                la Superintendencia de Industria y Comercio quejas por
                infracciones a lo dispuesto en la Ley 1581 de 2012 y en el
                Decreto 1377 de 2013, una vez haya agotado el trámite de
                consulta o reclamo ante el Responsable del Tratamiento; 5.
                Revocar la autorización y/o solicitar la supresión del dato
                cuando en el tratamiento no se respeten los principios, derechos
                y garantías constitucionales y legales; y 6. Acceder en forma
                gratuita a sus datos personales que hayan sido objeto de
                tratamiento.
              </p>
            </div>
            <div className="mb-[30px]">
              <h3 className="tituloSecundario text-center md:text-left mb-[20px] ">
                PROCEDIMIENTO PARA EJERCER LOS DERECHOS AL HABEAS DATA
              </h3>
              <p className="textoGeneral">
                Los titulares de la información podrán ejercer los derechos a
                conocer, actualizar, rectificar, revocar la autorización
                mediante el envío de una comunicación a la dirección y al área
                indicada en la presente Política. Esta comunicación deberá
                contener como mínimo lo siguiente:
                <br />
                <br />
                1. El nombre, domicilio del titular y medio de contacto para
                recibir la respuesta como teléfono, correo electrónico,
                dirección de residencia.2. Los documentos que acrediten la
                identidad o la representación de su representado.3. La
                descripción clara y precisa de los datos personales respecto de
                los cuales el titular busca ejercer alguno de los derechos.4. En
                caso dado, otros elementos o documentos que faciliten la
                localización de los datos personales. Una vez recibida la
                comunicación por parte del Responsable del Tratamiento, se
                procederá a dar respuesta dentro de los términos establecidos en
                la regulación aplicable.
              </p>
            </div>
            <div className="mb-[30px]">
              <h3 className="tituloSecundario text-center md:text-left mb-[20px] ">
                MODIFICACIÓN Y/O ACTUALIZACIÓN DE LA POLÍTICA DE PROTECCIÓN DE
                DATOS Y MANEJO DE INFORMACIÓN{' '}
              </h3>
              <p className="textoGeneral">
                Cualquier cambio sustancial en las políticas de tratamiento, se
                comunicará de forma oportuna a los titulares de los datos a
                través de los medios habituales de contacto y/o a través de la
                página de internet: http://www.pideypasa.com.co
              </p>
            </div>
            <div className="mb-[30px] md:mb-[100px]">
              <h3 className="tituloSecundario text-center md:text-left mb-[20px] ">
                VIGENCIA DE LAS POLÍTICAS DE TRATAMIENTO DE INFORMACIÓN PERSONAL
              </h3>
              <p className="textoGeneral">
                Las presentes políticas rigen a partir de la fecha de su
                aprobación. El franquiciante del Responsable del Tratamiento ha
                definido unas políticas de privacidad que aplican para todas sus
                franquiciados. En caso de inconsistencia o contradicción entre
                lo previsto en las mencionadas políticas de privacidad y estas
                Políticas de Tratamiento o la regulación aplicables,
                prevalecerán estas últimas. Fecha de modificación y
                actualización: 12 de Enero de 2022.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
