import mostrador from '../../../../Imgs/Home/confident.png'
import mesa from '../../../../Imgs/Home/worker.jpg'
import parqueadero from '../../../../Imgs/Home/parqueadero.png'

function Comfort() {
  return (
    <div className="flex justify-center md:w-[100%]">
      <div className="container">
        <div className="flex flex-col">
          <div className="h-[200px] flex flex-col justify-center md:h-[300px]">
            <h1 className="text-center text-xl text-gray-700 md:text-4xl mx-[10px] font-bold tituloPrincipal">
              Ahora los clientes podrán elegir cómo, dónde y cuándo recoger sus
              pedidos con <span className="text-Main">Pick Up </span>
            </h1>
          </div>
          <nav className="">
            <ul className="grid grid-cols-1 xl:grid-cols-3 gap-6 p-[30px] max-w-[600px] xl:max-w-full mx-auto xl:mx-0">
              <li className="text-center md:text-start shadow-xl flex flex-col">
                <img
                  className="mx-auto"
                  src={mostrador}
                  alt=""
                  height={350}
                  width={351}
                />
                <div className="pb-[30px] md:pb-[50px] p-10">
                  <p className="tituloSecundario my-[15px]">
                    Entrega en mostrador
                  </p>
                  <p className="textoGeneral">
                    Los clientes tienen la oportunidad de realizar sus pedidos
                    con antelación y elegir el momento en el que desean recoger
                    sus productos en el mostrador. De esta manera, pueden
                    disfrutar de ellos frescos y recién elaborados.
                  </p>
                </div>
              </li>
              <li className="text-center md:text-start  shadow-xl flex flex-col ">
                <img
                  className="mx-auto"
                  src={mesa}
                  alt=""
                  height={350}
                  width={350}
                />
                <div className="pb-[30px] md:pb-[50px] p-10 ">
                  <p className="tituloSecundario my-[15px]">
                    Entrega en la mesa
                  </p>
                  <p className=" textoGeneral">
                    Nuestros clientes pueden optar por que sus productos sean
                    entregados directamente en las mesas. Para facilitar el
                    proceso, cada mesa estará identificada con un número que
                    permitirá al mesero llevar el pedido correspondiente a cada
                    cliente.
                  </p>
                </div>
              </li>
              <li className="text-center md:text-start shadow-xl  flex flex-col">
                <img
                  className="mx-auto mt-12"
                  src={parqueadero}
                  alt=""
                  height={350}
                  width={350}
                />
                <div className="pb-[30px] md:pb-[50px] p-10">
                  <p className="tituloSecundario pb-2">
                    Entrega en parqueadero
                  </p>
                  <p className="textoGeneral">
                    Si eres de los que prefiere no bajarse del coche pero no
                    quiere renunciar a una deliciosa comida recién preparada,
                    ¡tenemos la solución perfecta! Ahora puedes disfrutar de
                    nuestro servicio de entrega por turnos en los parqueaderos
                    de los restaurantes.
                  </p>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Comfort
