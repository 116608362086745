import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import saveTime from '../../../../Imgs/Home/saveTime.png'

function About() {
  return (
    <>
      <div className="bg-[#F8F8F8FB]">
        <div className="container mx-auto px-4">
          <div className="flex flex-col justify-between lg:flex-row items-center">
            <div className="flex flex-col w-[90%] lg:w-[50%] mt-10 lg:mt-20">
              <h1 className="text-left text-xl text-gray-700 md:text-4xl mx-[10px] font-bold tituloPrincipal">
                ¿Qué es <span className="text-Main">Pide y Pasa</span>?
              </h1>
              <p className="text-sm md:text-xl text-justify mt-5">
                Pide y Pasa, una plataforma digital innovadora de la categoría
                FoodTech diseñada exclusivamente para el sector de restaurantes.
                Esta aquí para revolucionar la forma en que se gestionan los
                pedidos a sus clientes.
              </p>
              <p className="text-sm md:text-xl text-justify mt-5">
                Nuestro objetivo principal es mejorar la experiencia de compra
                con estrategias que hacen eficiente los tiempos de entrega de
                los productos a los usuarios, justo en el momento que el cliente
                decida recogerlo.
              </p>
              <Link to="/como-funciona" className="flex mt-10">
                <motion.button
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 1 }}
                  className="bg-Main mx-auto lg:mx-1 text-white w-[180px] h-[40px] rounded-3xl font-semibold "
                >
                  ¿Cómo funciona?
                </motion.button>
              </Link>
            </div>
            <div className="flex mt-7 lg:mt-0 lg:ml-8">
              <img src={saveTime} height={400} width={600} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
